import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`2021/22 Fellows`}</h1>
    <p><strong parentName="p">{`DesignInquiry has revived its fellowship program. Our 2021/2022 fellows will have opportunities for longer conversations, expanded networks, and radical access, all with the intention of developing and sharing new perspectives and outcomes on urgent and timely topics.`}</strong></p>
    <p>{`With pleasure, we introduce them to you.`}</p>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBQT/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHGro6ax5MA6QP/xAAbEAACAwADAAAAAAAAAAAAAAACAwAREgETMv/aAAgBAQABBQJpn2JeWo70AbKEPFLGmz//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAEAAgMBAAAAAAAAAAAAAAABAhAAESFh/9oACAEBAAY/AkOBmpdKMj5cSv/EABwQAAMAAQUAAAAAAAAAAAAAAAABESEQMUFxof/aAAgBAQABPyF1k/UQ30x0QpN4aK7CqvR/JCFllpsf/9oADAMBAAIAAwAAABBjCDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAEAAwADAQEAAAAAAAAAAAABABExIUFhUaH/2gAIAQEAAT8QrzQANYgLwCzvS4cIFpez0gCIcPqHRA+k3QJVOZErF1L8l4P2f//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "krishna",
          "title": "krishna",
          "src": "/static/43df858092c21f735d56a541239fcfd6/70ebb/fellows_krishna_headshot350.jpg",
          "srcSet": ["/static/43df858092c21f735d56a541239fcfd6/f93b5/fellows_krishna_headshot350.jpg 300w", "/static/43df858092c21f735d56a541239fcfd6/70ebb/fellows_krishna_headshot350.jpg 350w"],
          "sizes": "(max-width: 350px) 100vw, 350px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Krishna Balakrishnan is a visual artist and curator who uses graphic design, research and criticism to visually communicate ideas of everyday human experience (in and out of linear perspective) and cultural sustainability.`}</strong>{` In his visual art, he creates representation of hybrid identities through mixing concepts and materials relating to Hindu epics and myths, comic-book superheroes and Disney princesses; images and text intertwine to narrate a collection of diverse cultural references. He creates illustrations, collages, screen and Intaglio prints. He designs for books, magazines, posters, letters, marketing materials and websites. With a multi-disciplinary practice across a diverse clientele, Krishna currently works for London’s Natural History Museum, as a graphic designer within the temporary exhibition team at the design department. He also teaches Contextual and Theoretical Studies at the Design School, London College of Communication and Central Saint Martins. He has a Master of Design degree from York University in Toronto, Canada and an MA in curation from Central Saint Martins, University of Arts London.`}</p>
    <p>{`Krishna’s fellowship proposal talks about “Prepostness” in a philosophical and pedagogical way. He says: “It is philosophical from my own development process. Arundhati Roy’s Financial Times article from the beginning of the global pandemic lockdown states that “the pandemic is a portal.” She explains that “in the midst of this terrible despair, it offers us a chance to rethink the doomsday machine we have built for ourselves. Nothing could be worse than a return to normality.” The exposed inequalities that have surfaced during this pandemic, and feeling underwhelmed by traditional graphic design’s position in facilities to such disparities, has prompted me to exit from corporate design and focus on cultural studies in graphic design. I see my exit as an outcome, but at the same time a way for me to develop a practice that extends beyond being a problem, but rather a critical discourse.”`}</p>
    <p>{`Connect with Krishna on discord or find his work at bkrishna.com`}</p>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAd0+pSkFeAc8H//EAB0QAAICAQUAAAAAAAAAAAAAAAIDAAEEEBETIjH/2gAIAQEAAQUCtmwhZXozzlAURahNeOoWRnVn/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHRAAAgICAwEAAAAAAAAAAAAAAAECETFBEBJRkf/aAAgBAQAGPwJest1XEWVsy/pbyS7KySWmf//EABsQAQEBAAMBAQAAAAAAAAAAAAERACExQRBh/9oACAEBAAE/IbaflhlPgeN5TPSmJHvQwR3hZzvCSBmsmQOd/9oADAMBAAIAAwAAABADBzz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAfEAEAAgEEAwEAAAAAAAAAAAABABEhQWFxkTFRodH/2gAIAQEAAT8QtUaGg3iBhQpw8nuIj4jlBSTuXAZKGVuU6+QQEC937D/YKKUDGkdwHS83ziWlEsV0M//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "sarah",
          "title": "sarah",
          "src": "/static/e8624c67134a12a2cc4b9c96aa63b59a/70ebb/fellows_sarah_headshot350.jpg",
          "srcSet": ["/static/e8624c67134a12a2cc4b9c96aa63b59a/f93b5/fellows_sarah_headshot350.jpg 300w", "/static/e8624c67134a12a2cc4b9c96aa63b59a/70ebb/fellows_sarah_headshot350.jpg 350w"],
          "sizes": "(max-width: 350px) 100vw, 350px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Sarah Elawad is a multidisciplinary designer born and raised in the UK, with family roots in Sudan.`}</strong>{` She holds a BFA in Graphic Design from Virginia Commonwealth University of the Arts in Doha, Qatar. Based everywhere and nowhere, Sarah is co-director for the experimental printing project Water With Water based in Qatar. She recently completed a design residency at the Fabrica Research Centre in Treviso, Italy and will be starting her MFA in Graphic Design at the Yale School of Art in the Fall. Sarah has a current strong interest in internet art, digital collage and experimental graphics. Her hybrid family history and travel lifestyle has influenced her work to centre around the cultural relationships between the West and the Middle East.`}</p>
    <p>{`Sarah will be using her fellowship to explore the relationship between the ideal worlds we go to when we imagine, and the ideal worlds we interact with through technology. She would like to delve deeper into the human ability to imagine, attempting to mindfully design or bring imaginations to life whilst monitoring how the information and content we are exposed to on social media influences or does not influence our idealized worlds.`}</p>
    <p>{`Connect with Sarah on our Discord server, or at `}<a parentName="p" {...{
        "href": "http://www.sarahelawad.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.sarahelawad.com`}</a></p>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "350px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "100%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDAQL/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHlSYueRrkEQP/EABsQAAICAwEAAAAAAAAAAAAAAAEDAAIREhNB/9oACAEBAAEFArMv0VvMCPrgpYCzhSeLArGOZv8A/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAHhAAAgEDBQAAAAAAAAAAAAAAAAERAhIhEBMiMaH/2gAIAQEABj8CspwO6pGX6blvJGF3pA4SHFUH/8QAHBABAAMBAQADAAAAAAAAAAAAAQARITFRQWGh/9oACAEBAAE/IXHfdWy0uTkPYfqAwOjUS/u2G3db7Ftr5mCyZdbCEg8J/9oADAMBAAIAAwAAABDwzzz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAdEAEBAAICAwEAAAAAAAAAAAABEQAhUWFBcaGB/9oACAEBAAE/EGKCunnn1glA4bHrAHwifMNBmLbZrfOsgSlUafeDVdzFt4JH9yUWUTo7cMDIEJn/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "samer",
          "title": "samer",
          "src": "/static/9969b9d7fc87ce0116d5918d275962de/70ebb/fellows_samer_headshot350.jpg",
          "srcSet": ["/static/9969b9d7fc87ce0116d5918d275962de/f93b5/fellows_samer_headshot350.jpg 300w", "/static/9969b9d7fc87ce0116d5918d275962de/70ebb/fellows_samer_headshot350.jpg 350w"],
          "sizes": "(max-width: 350px) 100vw, 350px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Samer Fouad is an artist and graphic designer. Although his degree is in graphic design, Fouad considers himself a mixed media artist, combining sculpture, video, graphic design, photography, digital collage, and various printmaking methods.`}</strong>{` His work has been showcased around the world, including New York City, Japan, Qatar, France, Spain, Italy and Hungary. Fouad has been published in The AIGA, Adweek, Beautiful Bizarre Magazine and Design Sponge. Fouad has lectured and given design-based workshops at many universities and studios around the world, and recently gave a TEDTalk titled “Comfort Kills Creativity.” Fouad was an artist-in-residence for the Mana BSMT program, located at Mana Contemporary, Jersey City, Palazzo Monti in Brescia, Italy, and Nouvelle Vague Gallery in Marbella, Spain. He is a co-founder of the Newark Print Shop in New Jersey. Simultaneous to his career as an artist, Fouad taught advanced printmaking and graphic design in the undergraduate BFA program and book arts in the MFA program at Rutgers University, New Jersey. He was also a teaching assistant in visual communication design for the undergraduate School of Art and Design and was a lecturer for the Masters program of the School of Human Centered Design and Engineering, at The University of Washington. He is currently an Assistant Professor of Design at Pacific Lutheran University. Samer Fouad holds a BFA degree BFA from the Arts, Culture and Media program at Rutgers University with a concentration in graphic design and a minor in art history. He also holds a Master of Design degree from the University of Washington.`}</p>
    <p>{`Samer Fouad is currently living in the prepostness of his new body of work. During this fellowship, he hopes to collaborate with other design-based artists who work with their hands as much as their keyboards. He wants to create something that will outlast him. He wants an exquisite corpse of objectivity, an artifact that allow form to follow function and upholds 1+1=3.`}</p>
    <p>{`Say hello to him on DI’s discord server + check out his work: `}<a parentName="p" {...{
        "href": "http://www.samerfouad.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.samerfouad.com`}</a>{`.`}</p>
    <hr></hr>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "710px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAABjSvoFBYf/8QAGxAAAQQDAAAAAAAAAAAAAAAAAQIDEjEAEUT/2gAIAQEAAQUCMRh0AwqTXUul3//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAICAgMAAAAAAAAAAAAAAAERADECAyAyYf/aAAgBAQAGPwLIIPyDW+twO+H/xAAcEAEAAwACAwAAAAAAAAAAAAABABEhUWExQcH/2gAIAQEAAT8h91NUiogrUcjz3FYrGMdd8fISuTI7J//aAAwDAQACAAMAAAAQwA//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPxBpm4dJ7jwywmQlF6GnZuS/+F/o1v2ei1zHCWoud3CLCkVrt2f/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "andy and jess",
          "title": "andy and jess",
          "src": "/static/624dc2f213bd0c69cf3d4a54a26ab7f3/67e9d/fellows_andyjess_headshot350.jpg",
          "srcSet": ["/static/624dc2f213bd0c69cf3d4a54a26ab7f3/f93b5/fellows_andyjess_headshot350.jpg 300w", "/static/624dc2f213bd0c69cf3d4a54a26ab7f3/b4294/fellows_andyjess_headshot350.jpg 600w", "/static/624dc2f213bd0c69cf3d4a54a26ab7f3/67e9d/fellows_andyjess_headshot350.jpg 710w"],
          "sizes": "(max-width: 710px) 100vw, 710px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p><strong parentName="p">{`Andy Campbell and Jessica Brier are using their fellowship to work on a prospective book project entitled `}<em parentName="strong">{`Displaying Design: Life Imagined in Objects, Graphics, and Fashion from 1851 to Today`}</em>{`, a sourcebook offering a selected history of design exhibitions.`}</strong>{` They think such a text is long overdue. While compendiums of historical exhibitions already exist for art and architecture (wherein sometimes design is a bit player), there is not yet a book that takes a broad overview of key exhibitions that have featured objects and practices of graphic, industrial, and fashion design. They want this book to showcase design’s ability to produce and question the time in which it is made, and because exhibitions are temporary, spatially constructed narratives, they are a perfect aperture to engage with a broader history of design.`}</p>
    <p><strong parentName="p">{`Andy Campbell`}</strong>{` is a historian and critic of art and design who foregrounds LGBTQ communities and archives as wellsprings for alternative histories. He is the author of two books—Queer X Design: 50 Years of Signs, Symbols, Banners, Logos, and Graphic Art of LGBTQ (Black Dog & Leventhal, 2019) and Bound Together: Leather, Sex, Archives, and Contemporary Art (Manchester University Press, 2020)—and, along with Amelia Jones, co-edited the catalog Queer Communion: Ron Athey (Intellect, 2020), which was named one of the “Best Art Books of 2020” by The New York Times. His criticism and academic writing can be found in Artforum, The Invisible Archive, GLQ, Aperture, and Dress, amongst other venues. He holds a doctorate in art history from the University of Texas at Austin and a BA from Oberlin College. He currently works in Los Angeles, California as an Associate Professor of Critical Studies at USC’s Roski School of Art + Design, and he lives in Long Beach. Connect with Andy on DesignInquiry’s Discord server, and see more of his work at `}<a parentName="p" {...{
        "href": "http://www.andycampy.com",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`www.andycampy.com`}</a>{`.`}</p>
    <p><strong parentName="p">{`Jessica Brier`}</strong>{` is a curator and historian of art and design specializing in the intermediality of photography, printmaking, graphic design, and the built environment. She currently serves as Deknatel Curatorial Fellow in Photography at the Loeb Art Center at Vassar College in Poughkeepsie, New York. Formerly, she was a curatorial assistant in photography at the San Francisco Museum of Modern Art (SFMOMA), where she worked on exhibitions including Public Intimacy: Art and Other Ordinary Acts in South Africa (2014), South Africa in Apartheid and After: David Goldblatt, Ernest Cole, Billy Monk (2012-2013), and Francesca Woodman (2011-2012). Her writing has appeared in Suzanne Lacy: We Are Here (DelMonico Books, 2019) and in art on paper, Curating Now, and on SFMOMA’s blog, Open Space. She holds a doctorate in art history from the University of Southern California, an MA in Curatorial Practice from the California College of the Arts, and a BA from New York University.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      